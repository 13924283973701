.iconButton {
  position: relative;
  bottom: 2px;
}

.cardPrices {
  text-align: center;
  font-weight: 700;
  font-size: 1.125em;
}

.sale {
  color: #dc3545;
}

.sale s {
  color: #212529;
}

.old-price, .old-price s {
  color: #666;
}

.old-price::after {
  content: "*";
}

.cardLinks {
  font-size: 1.5em;
  text-align: center;
  display: flex;
  justify-content: space-around;
}

.card-body {
  padding: 0.5rem 0.5rem;
}

.cardLinks .card-link {
  margin: 0;
}

.card-img {
  padding: 0 1.75em;
}

.unitSize {
  text-align: center;
}

.unitPrice {
  text-align: center;
  font-size: .8em;
}

body {
  background: #efeeea;
}

.main-content {
  min-height: 100vh;
}

.navbar {
  margin-bottom: 1.5em;
}

.navbar a {
  text-decoration: none;
  color: #f8f9fa;
}

.iconLink {
  display: flex;
  align-items: center;
}

h1 a {
  color: #efeeea;
}

h1 a:hover {
  color: #efeeea;
}

h1 {
  color: #efeeea;
  font-size: 1.25em;
  margin: 0 .5em;
}

.productImage {
  border: 1px solid #212529;
  border-radius: 5px;
  background: #fff;
  padding: .5em;
}

.home-icons {
  text-align: center;
  font-size: 1.75em;
}

.home-icons p {
  font-size: .625em;
}

h4 {
  font-weight: 300;
}


form input.d-sm-none {
  width: 100%;
  margin-bottom: .5rem;
}

form button.d-sm-none {
  width: 100%;
  margin-top: .5rem;
}

.basketSelect {
  background: #efeeea;
  border: 0;
  width: auto;
}

.footer {
  color: #efeeea;
  margin-top: 1.5em;
}

.footer .container {
  padding: 1rem;
  text-align: center;
  font-weight: 300;
  font-size: 1rem;
}

.footer a {
  color: #EA858F;
}

.basketsTable {
  max-width: 800px;
  text-align: center;
}

.basketsTable td:nth-child(1), .basketsTable th:nth-child(1) {
  text-align: left;
  cursor: pointer;
}

.basketsTable svg {
  cursor: pointer;
}

.alert a {
  text-decoration: none;
}

button.expandButton {
  border: 0;
  background: none;
  width: 1em;
}

.badge.bg-warning {
  color: #222;
}

.badge {
  max-width: 100%;
  overflow: hidden;
}

body a {
  color: #0B62E5;
}

.GoogleAdSense {
  padding-bottom: 1rem;
}

.responsive_ads {
  width: 320px;
  height: 100px;
  margin: 0 auto;
}

@media(min-width: 500px) {
  .responsive_ads {
    width: 468px;
    height: 60px;
  }
}

@media(min-width: 800px) {
  .responsive_ads {
    width: 728px;
    height: 90px;
  }
}

.comparison-city-select {
  background: transparent;
  border: 0;
  float: right;
  width: auto;
  appearance: menulist;
}